
html,body {
    /* height: 100%; */
    font-family: 'Poppins', sans-serif !important;
}
.react-tel-input .form-control {
    font-size: 18px !important;
    font-family: 'Poppins', sans-serif !important;
}
p,h1,h2,h3,h4,h5,h6,button{
margin:0 !important;
font-family: 'Poppins', sans-serif !important;
}
div#root{

}
.MainWhole-wrapper-container-2{
    width:560px;
    background-color: #ffffff;
    margin:auto;
    height: 100vh !important;
    /* border: 2px solid rgb(196 193 193); */
    border-top: 0px;
    /* height: 99vh; */
}
.MainWhole-wrapper-container{
    width:560px;
    background-color: rgb(242,242,242);
    margin:auto;
    /* height: 100vh !important; */
    /* border: 2px solid rgb(196 193 193); */
    border-top: 0px;
    /* height: 99vh; */
}
.DesktopMain-wrapper{
    background-color: rgb(242,242,242);
    /* display: flex;
    align-items: center; */
}
.Login_page-wholeMain-wrapper{
        display: flex;
    align-items: center;
}
.DesktopMain-wrapper .Header-login-logout-btn {
    cursor: pointer;
    color : black;
    text-decoration: none;
    font-weight: 600;
}
.admin-header-acitve{
    color:rgb(47, 94, 164) !important;
}
.MainHeader-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 18px;
    background: white;

}
.AdminHeader-right-side-wrapper{
display: flex;
align-items: center;
justify-content: space-between;
}
.AdminHeader-right-side-wrapper div{
    display: flex;
    flex-direction: column;
    margin:20px;
    margin-top:0px;
    margin-bottom:0px;
    align-items: center;
}
.MainHeader-logo-div{
    width:50%;
    text-align: start;
    display: flex;
    align-items: flex-start;
    /* justify-content: center; */
}
.MainHeader-logo-div img{
    width:66%;
}
.Main-Wrapper-Container, .ItemListMain-Wrapper, .CartPage-Main-wrapper, .QR-Code-wrapper-whole {
padding:30px;
position: relative;
/* padding-bottom:10px; */
}
.ItemListMain-Wrapper, .CartPage-Main-wrapper {
    padding-bottom: 30px;
}
.QR-Code-wrapper-whole {
    padding-bottom: 30px;
}

.Free-delivery-wrapper {
    text-align: left;
    background: linear-gradient(to right, rgb(244,123,38), rgb(238,48,108));
    width: 50%;
    border-radius: 0px 0px 10px 10px;
    padding: 17px;
    margin-left: 0px;
    margin-top: -10px;
    padding-top: 28px;
}



.product-images-class {
    width: 100%;
    /* border: 3px solid rgb(242,242,242); */
    height: auto;
}

.product-content-wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 14px;
    width:100%;
}

.product-content-wrapper-inner-div-one {
    width: 17%;
    border: 1px solid #dddddd;
    border-radius: 4px;
    padding: 5px;
    background: #ffffff;
}
.product-content-wrapper-inner-div-two {
    width: 72%;
    text-align: left;
    margin-left: 32px;
}
.product-content-wrapper-inner-div-two h4{
    font-size:12px;
    font-weight: 700;
    font-family: 'Poppins',sans-serif;
    margin-bottom: 6px;
    margin-top:0px;

}
.product-content-wrapper-inner-div-two p{
margin:6px;
font-size:11px;
}
.ViewAll-Items-wrapper button{
    background-color: rgb(50 89 149) !important;
    border: none !important;
    color: white !important;
    text-transform: capitalize !important;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 500 !important;
    font-size: 13px !important;
    width: 95%;
    padding: 6px 9px;
    border-radius: 6px;
}
.ViewAll-Items-wrapper {
    margin-top: 15px;
}
/* ===================Landing_page_header_css=================================================================================================================================================================================================================================================== */

.Landing_page_Header_image_wrapper img{
width:50%;
margin: 30px;
}
h1.HomePage-Main-Heading-text {
    width: 70%;
    margin: auto !important;
    font-size: 33px;
    line-height: 40px;
}
.HomePage-Main-Heading-text span{
    color: rgb(97,188,71);
}
.HomePage_slider_div-class{
    background-repeat: no-repeat;
    height: 244px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: baseline;
    background-size: 332px !important;
    background-position: right;
}
.HomePage_slider_content_div_content{
    margin-bottom: 5rem;
    text-align: left;
    margin-left: 35px;
}
.HomePage_slider_content_div_content label{
    background-color: rgb(75,79,91);
    color: white;
    padding: 4px 13px;
    border-radius: 44px;
    text-transform: uppercase !important;
    font-weight: 600;
}
.HomePage_slider_content_div_content h4 {
    margin-top: 9px !important;
    font-size: 24px;
    width: 66%;
    line-height: 30px;
}
  .owl-theme .owl-nav {
    margin-top: 10px;
    text-align: center;
    -webkit-tap-highlight-color: transparent;
    position: absolute;
    top: -23px;
    left: 2rem;
}
.HomePage-Main-Wrapper-Container button.owl-prev , .HomePage-Main-Wrapper-Container button.owl-next{
    font-size:60px !important;
    font-weight: 300 !important;
    margin: 10px !important;
    color:rgb(97,188,71) !important;
}
.HomePage-Main-Wrapper-Container button.owl-dot.active span{ 
    background-color:rgb(97,188,71) !important;
    width: 13px !important; 
    height: 13px !important;
}
.HomePage-Main-Wrapper-Container button.owl-prev:hover, .HomePage-Main-Wrapper-Container button.owl-next:hover{
    background-color: transparent !important;
}
.HomePage_order_now_button {
    background-color: rgb(97,188,71);
    padding: 7px 34px;
    color: white;
    border: none !important;
    border-radius: 30px;
    font-size: 31px;
    font-weight:600;
    margin:20px !important;
    cursor:pointer;
    position: relative;
    z-index: 99999999 !important;
}
.HomePage_section_two_wrapper img{
width:60%;
}
.HomePage_How_It_Works_text {
    font-size: 18px;
    margin-top: 10px !important;
}
/* ===================End=================================================================================================================================================================================================================================================== */
/* ===================HomePageSecond=================================================================================================================================================================================================================================================== */
.HomePage_How_It_Works_text_second {
    background-color: rgb(75,79,91);
    width: 21%;
    margin: auto !important;
    position: absolute;
    color: white !important;
    left: 36%;
    padding: 11px 25px;
    top: -5%;
    font-weight: 400;
    border-radius: 30px;
    font-size: 18px;
}
.HomePage_second_Youtube_content_wrapper{
    position: relative;
    margin-top: 39px;
    margin-bottom: 29px;
}
.HomePage_Youtube_Video{
width:100%;
height:350px !important;
}
.HomePage_order_now_button_second{
    background-color: rgb(97,188,71);
    padding: 7px 34px;
    color: white;
    border: none !important;
    border-radius: 30px;
    font-size: 19px;
    font-weight:600;
    margin:20px !important;
    position: absolute;
    right: 30%;
    bottom: -25px;
}
.ytp-chrome-top.ytp-show-cards-title{
    display: none !important;
}
/* ===================End=================================================================================================================================================================================================================================================== */
/* ===================Footer=================================================================================================================================================================================================================================================== */
.Footer_Content_wrapper {
    margin-top:5px;
}
.Footer_Content_wrapper p {
    font-weight: 500;
    margin-bottom: 4px !important;
    font-size: 15px;
}
.Footer_Content_wrapper img{
  width:10%;
}
/* ===================End=================================================================================================================================================================================================================================================== */

/* ==============================ItemListPage====================================================================================================================================================================================================================================== */
.ItemList_Page_Logos_Wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    padding: 16px 12px;
    border-radius: 10px;
}
.ItemList_Logo_div_one img{
width:60%;
}
.ItemList_Logo_div_two img {
    width: 100%;
}
.Logos_Below_div_wrapper {
    background-color: rgb(97,188,71);
    color: white;
    width: 70%;
    margin: auto;
    padding: 7px;
    border-radius: 0px 0px 10px 10px;
}
.Logos_Below_div_wrapper p{
    font-weight: 500;
    font-size: 13px;
}
.ItemListMain-Wrapper .HomePage-Shop-To-Gate-Wrapper{
    padding: 20px;
    /* margin: 19px; */
    /* margin-bottom:10px; */

}
.ItemList-Shop-To-Gate-Wrapper-div-two{
    text-align:left;
    padding-left: 30px;
}
.ItemList-Shop-To-Gate-Wrapper-div-two p {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 15px;
    margin:0;
 }
.ItemList-Shop-To-Gate-Wrapper-div-one{
   /* padding-left: 20px; */
}
.ItemsListPage-ItemsList-containers{
    display: flex;
    justify-content: center;
    align-items: center;
  /*  padding: 9px 19px; */
    align-items: center;
}
.ItemsListPage-ItemsList-containers .product-images-class {
    width: 100%;
    /* border: 3px solid rgb(242,242,242); */
    height: auto;
} 
.ItemsListPage-ItemsList-containers .product-content-wrapper-inner-div-two h4 {
    font-size: 13px;
    margin-bottom:10px;
    line-height:16px;
}
.ItemsListPage-ItemsList-containers .product-content-wrapper-inner-div-two p {
     margin: 6px; 
    font-size: 12px;
}
.ItemsListPage-ItemsList-Counter-wrapper {
    display: flex;
    justify-content: inherit;
    align-items: center;
    width: 35%;
    align-self: center;
}
.ItemsListPage-ItemsList-Counter-wrapper_Two{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    align-self: center;
    margin:auto !important;
}
.ItemList_Page_Next_button {
    background-color: rgb(97,188,71) !important;
    color: white !important;
    border: none;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0%;
    width: 100%;
    padding: 13px 17px;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
    cursor: pointer;
}
.ItemsListPage-ItemsList-wrapper-whole {
    margin-top: 20px;
}
.ItemsListPage-ItemsList-Counter-input {
    background: transparent;
    border: none;
    width: 20%;
    color: rgb(17, 43, 82);
    text-align: end;
    font-weight: 600;
    font-size: 16px;
    /* padding-left: 14px;
    padding-right:25px; */
}
.ItemsListPage-ItemsList-Counter-input:focus{
    outline: none;
}
.ItemsListPage-ItemsList-Counter-btn {
    background: #ffffff;
    border: 1px solid rgb(221 219 219);
    padding: 0px 10px;
    font-weight: 600;
    font-size: 20px;
    color: rgb(17, 43, 82);
}
.ItemsListPage-ItemsList-Counter-btn_Two{
    background: #ffffff;
    border: 1px solid rgb(221 219 219);
    padding: 0px 10px;
    font-weight: 600;
    font-size: 20px;
    color: rgb(17, 43, 82);
    border-radius:10px;
}
.ItemListpage-ViewCart-wrapper{
    background-color: rgb(21, 64, 129);
    color:white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /*padding:0px 34px;*/
     bottom:0;
     position:fixed;
    left:50%;
    transform:translate(-50%,0);
    width:560px;
}
.ItemListpage-ViewCart-wrapper p{
    font-family: 'Poppins', sans-serif !important;
    font-weight:600;
    cursor: pointer;
    margin:15px 25px;
}
.ItemListpage-ViewCart-wrapper-p-two{
   display: flex;
   align-items: center;
   font-family: 'Poppins', sans-serif !important;
   font-weight:400 !important;
   color:white !important;
   text-decoration: none !important;
}
.ItemListpage-ViewCart-wrapper-p-two a{
    Color:white !important;
    text-decoration: none !important;
}
.ItemListpage-ViewCart-wrapper-p-two span{
    margin-left:10px;
}
.ItemList_Image_content{
        position: absolute;
        top:10px;
        left:20px;
        color:white;
        font-weight:bold;
    }
    .ItemList_page_Single_Item{
        max-width: unset !important;
        width: 47.5%;
        margin: 0px !important;
        margin-bottom: 20px !important;
    }
    .Item_Individual_Image{
        padding-top: 36px;
        padding-bottom: 10px;
    }
    .Item_Individual_btn_content{
        padding-bottom:20px !important;
        padding:10px !important;
    }  
    .ItemList_Page_2_List_wrapper_Main_Whole{
        grid-column-gap:15px;
    }  
/* ==============================End===================================================================================================================================================================================================================================== */
/* ==============================ItemListTwo===================================================================================================================================================================================================================================== */
.ItemList_Page_2_List_wrapper_Main_Whole {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top:20px;
}
/* ==============================End===================================================================================================================================================================================================================================== */

/* ==============================DetailsPage===================================================================================================================================================================================================================================== */
.DetailsPage-Main-wrapper {
    /* margin-top: 24px; */
    padding:25px;
}
.DetailsPage-content-wrapper {
    /* background: #fff; */
    /* padding: 20px; */
    /* margin: 32px; */
    border-radius: 14px;
    margin-top: 17px;
}
.DetailsPage-content-wrapper .Mui-selected::after{
content:"";
border:none !important;
}
.DetailsPage-content-wrapper .css-1bjk3jo-MuiButtonBase-root-MuiBottomNavigationAction-root {
    border: 2px solid rgb(221 219 219) !important;
    border-radius: 30px !important;
    padding: 22px 9px ;
    margin: 4px !important;
    min-width:65px !important;
}
.DetailsPage-content-wrapper .css-1bjk3jo-MuiButtonBase-root-MuiBottomNavigationAction-root.Mui-selected {
    background-color: rgb(97,188,71);
    border-radius: 30px;
    padding: 0px 10px;
    letter-spacing: 1px;
    font-family: 'Poppins', sans-serif !important;
    color:white;
}
.DetailsPage-content-wrapper-inner {
    /* margin-top: 27px; */
    margin-bottom: 10px;
}
.DetailsPage-content-wrapper .css-9npbnl-MuiFormLabel-root-MuiInputLabel-root {
    text-align: left !important;
    font-weight: 900;
    font-size: 17px;
    color:rgb(97,188,71);
    /* padding-left:20px; */
}
.DetailsPage-Main-wrapper h2{
    text-align: left;
     /* padding-left:33px; */
     margin-bottom:0px;
     font-size: 20px;
}
.Details-page-Nextbutton-wrapper button {
    background-color: rgb(97,188,71);
    color: white;
    padding: 13px 17px;
    border: none;
    width: 100%;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
}
.Details-page-Nextbutton-wrapper {
   /* margin-top: 12rem; */
     bottom: 0;
    position: fixed;
    left: 50%;
    transform: translate(-50%, 0);
    width: 560px;
}
.DetailsPage_DeliveryLocation_Wrapper{
    display: flex;
 flex-wrap: wrap !important;
}
.detailspage-content-label {
     text-align: left !important;
    font-weight: 700 !important;
    font-size: 17px !important;
    color: rgb(97,188,71) !important;
    font-family: 'Poppins', sans-serif !important;
}
.Details_page_selected_deivery_location_wrapper{
    text-align: left;
}
.Details_page_selected_deivery_location_wrapper span{
    color: rgb(97,188,71) !important; 
}
/* ==============================End===================================================================================================================================================================================================================================== */

/* ==============================CartPage===================================================================================================================================================================================================================================== */

.CartPage-Main-wrapper .DetailsPage-content-wrapper-inner:nth-child(1){
display: flex;
align-items: center;
justify-content: space-between;
}
.CartPage-Main-wrapper .DetailsPage-content-wrapper-inner div{
    text-align: left;
      width: 100%;
    padding: 0;
    margin-top: 3px;
    justify-content: center;
}
.CartPage-Main-wrapper .detailspage-content-label {
    
    font-size: 13px;
    text-align: left !important;
}

.CartPage-Main-wrapper .DetailsPage-content-wrapper-inner p{
margin:0px;
margin-top:6px;
/* padding-left:20px; */
}
.CartPage-Main-wrapper h2{
    text-align: center;
    /* padding-left:33px; */
    margin-bottom:0px;
    font-size: 20px;  
}
.CartPage-Main-wrapper p {
    font-size: 15px;
}
.CartPage-Main-wrapper {
   /* margin-top: 36px; */
}
.CartPage-Main-wrapper .ItemsListPage-ItemsList-Counter-wrapper {
    display: flex;
    justify-content: end !important;
    align-items: center;
    width: 100%;
    margin-top: 17px;
    margin-left: 5rem;

}
.CartPage-Main-wrapper .product-content-wrapper-inner-div-one{
width:130px;
}

.CartPage-Main-wrapper .ItemsListPage-ItemsList-containers .product-images-class {
    width: 100%;
    /* border: 3px solid rgb(242,242,242); */
    height: auto;
}
.CartPage-Main-wrapper .product-content-wrapper {
    width: 50%;
}
.CartPage-Main-wrapper .CartPage-Product-wrapper-h2 {
    /* padding-left: 11px; */
    font-size: 18px;
    text-align: left;
}
.CartPage-amount-padi-wrapper {
    background: rgb(97,188,71);
    display: flex;
    color: white;
    justify-content: space-between;
    align-items: center;
    padding: 13px 19px;
 /*   margin: 32px; */
    margin-top: 0px;
    border-radius: 0px 0px 14px 13px;
}
.CartPage-Main-wrapper .DetailsPage-content-wrapper {
    background: #fff;
    padding: 20px;
    margin:0;
    border-radius: 10px;
    margin-top: 17px;
    margin-bottom:0px;
}
.CartPage-content-wrapper{
    padding:20px;
    /* margin:20px; */
}
.CartPage-amount-padi-wrapper p {
    margin: 6px;
    font-size: 17px;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 300;
}
.CartPage-amount-padi-wrapper h4{
    margin:0px
}
.CartPage-Main-wrapper .ItemsListPage-ItemsList-containers .product-content-wrapper-inner-div-two p{
font-size:16px;
}
.Cart_page_Resend_button_wrapper {
    text-align: center;
    margin-top: 32px;
}
.Cart_page_Resend_button_wrapper p {
    margin: 11px !important;
}
.Cart_page_Resend_button_wrapper button {
    border: none;
    background-color: rgb(97,188,71);
    color: white;
    border-radius: 30px;
    padding: 12px 25px;
    font-size: 15px;
    font-weight: 600;
}
.Cart_page_otp_input input {
    width: 30px !important;
    height: 30px !important;
    border: 1px solid #dddddd;

}
.Cart_page_selected_deivery_location_wrapper{
    text-align: center !important;
    margin-top: 30px;
   } 
   .Cart_page_selected_deivery_location_wrapper span{
    color:rgb(97,188,71);
   }  
/* ==============================End===================================================================================================================================================================================================================================== */

/* ==============================Congratulation page===================================================================================================================================================================================================================================== */

.Congratulation-content-div-one-wrapper {
   /* background-color: rgb(119,162,47); */
    background-color: #77b43f;
    color: white;
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 10px 20px;
}
.Congratulation-content-div-one-wrapper-inner-two{
text-align: center;
padding-left:20px;
}
.Congratulation-content-div-one-wrapper-inner-two h1 {
    font-size: 34px;
    font-family: 'Poppins', sans-serif !important;
    margin:0;
}

.Congratulation-content-div-one-wrapper-inner-two p {
    font-size: 23px;
    margin: 0px;
    font-weight: 600;
}
.Congratulation-content-div-one-wrapper-inner-one {
    border-radius: 100%;
    width: 107px;
    height: 100px;
    overflow: hidden;
}
.Congratulation-content-div-one-wrapper-inner-one img {
    /* width: 135px;
    height: 130px; */
    width: 129%;
    height: auto;
    border-radius: 100%;
    object-fit: fill;
    padding: 0px;
    margin-top: -15px;
    margin-left: -15px;
}
.Congratulation-page-order-wrapper-below-QR{
    display: flex;
    align-items: center;
    background-color: #fff;
    padding:10px 30px;
    margin:20px;
    justify-content: space-between;
}
.Congratulation-page-order-wrapper-below-QR h5{
    margin:0px;
    color:rgb(97,188,71);
    font-family: 'Poppins', sans-serif !important;
    font-weight: 800;
}
.Congratulation-page-order-wrapper-below-QR p{
    margin:0px;
    font-weight: 500;
    font-size:13px;
}
.QR-Code-wrapper-whole-inner-one h4 {
    font-size: 18px;
    margin: 0px;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 600;
    line-height: 18px;
}
.QR-Code-wrapper-whole-inner-one p {
    font-size: 15px;
    line-height: 20px;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 500;
}
.QR-Code-wrapper-whole-inner-one {
   /* padding: 26px; */
   margin-bottom:24px;
}
.Congratulation-page-invoice-wrapper-table-whole{
    width:92%;
    margin:20px;
    border-collapse: collapse;
}
.Congratulation-page-invoice-wrapper-table-whole thead{
border-bottom: 2px solid rgb(97,188,71) !important;
color: rgb(97,188,71) !important;
padding-bottom:20px !important;
}
.QR-Code-main-inner img {
    width: 200px !important;
    height: 200px !important;
    background: #FFF;
    padding: 10px;
}
.Congratulation-page-invoice-wrapper-table-whole tbody tr td {
    padding: 9px;
    padding-left: 0px;
    font-size: 13px;
}
.Congratulation-page-subtotal-wrapper , .Congratulation-page-total-wrapper{
    text-align: right;

}
.Congratulation-page-subtotal-wrapper {
    margin: 30px;
}
.Congratulation-page-subtotal-wrapper h4{
    margin:4px;
}
.Congratulation-page-subtotal-wrapper h4 span {
    color:black;
    font-weight: 400; 
}
.Congratulation-page-total-wrapper{
    margin: 30px;  
    background: #DCE1E7;
    font-weight: 700;
    color:rgb(97,188,71) !important;
    padding: 10px;
}
.Congratulation-page-total-wrapper p{
    margin:0px;
}
.Congratulation-page-total-wrapper p span{
    font-weight: 400; 
}
.Congratulation-Main-wrapper{
    padding-bottom: 20px;
}
/* ==============================End===================================================================================================================================================================================================================================== */

/* ==============================Login===================================================================================================================================================================================================================================== */
.Login-page-form {
    width: 25%;
    margin: auto;
    background: #ffffff;
    box-shadow: 0px 4px 33px 12px #d6d6d6;
    padding: 25px;
    padding-top: 37px;
    border-radius: 8px;
}
.LoginPage-Main-wrapper {
    height: 700px;
    align-items: center;
    align-self: center;
    vertical-align: middle;
    background-color: white;
    display: flex;
    width:100%;
}
.Login-page-form .css-1a1fmpi-MuiInputBase-root-MuiInput-root{
    width:95%;
    margin:auto;
}
.Login-page-form-logo-image{
    width:48%;
}
.Loginpage-main-form-inner-div{
margin-top:25px !important;
}
.Login-page-form p {
    margin: 5px;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    color: rgb(21, 64, 129);
    font-weight: 600;
    margin-top:4px !important;
}
.Login-page-form h6 {
    margin: 5px;
    font-family: 'Poppins', sans-serif;
    font-size: 13px;
    font-weight: 400;
    font-size: 15px;
}
.Loginpage\=-main-form {
    margin: 24px;
    margin-right: 0px;
    margin-left: 0px;
    margin-bottom: 13px;
    margin-top: 29px;
}
.Login-page-form .css-1x51dt5-MuiInputBase-input-MuiInput-input{
    font-size: 16px !important;
    font-weight: 600 !important;
    color:'#a89f9f' !important;
    letter-spacing: 0px !important;
}
.Login-page-form .css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
    font-size: 13px;
    font-family: 'Poppins', sans-serif;
    color:black;
    font-weight: 500;
}
.Loginpage-btn-wrapper {
    background-color: rgb(45 87 152);
    padding: 10px;
    border-radius: 7px;
}
.Login-page-form button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.Loginpage-btn-wrapper-btn.css-1e6y48t-MuiButtonBase-root-MuiButton-root{
    color: #ffffff !important;
    text-transform: capitalize;
    font-family: 'Poppins', sans-serif !important;
    width: 100%;
}
.Login-page-form .css-1d1r5q-MuiFormHelperText-root.Mui-error{
font-weight: 400;
font-size: 10px;
padding-left:39px;
}
/* ==============================End===================================================================================================================================================================================================================================== */

/* ==============================Checkout===================================================================================================================================================================================================================================== */
.Checkout-StripeMain-wrapper{  
    padding: 30px;
    height:580px; 
}
.Checkout-StripeMain-wrapper h1 {
    text-align: left;
    color: rgb(98,90,254);
    font-size: 61px;
    margin: 0px;
    letter-spacing: -2px !important;
    padding-left: 11px;
    font-family: 'Poppins', sans-serif;
}
.Checkout-StripeMain-wrapper h3 {
    text-align: left;
    padding-left: 18px;
    margin-top: 4px;
}
.Checkout-StripeMain-wrapper .FormGroup {
    background: transparent;
    box-shadow: none;
    padding: 5px;
    border: 1px solid black;
}
.Checkout-StripeMain-wrapper .FormRow{
    border-top: none !important;
}
.CheckoutStripe-content-wrapper{
    background-color: #ffffff !important;
    padding:20px !important;
}
/* ==============================End===================================================================================================================================================================================================================================== */
/* =================================SelectDeliveryLocation================================================================================================================================================================================================================================== */
.Select_delivery_page_inner_wrapper{
    text-align: left;
    padding-top: 15px;
    /* padding:33px; */
}
.Select_delivery_page_InnerHeading{
    color:rgb(97,188,71);
    font-weight: 700;
}
.Select_delivery_location_delivery_name{
    /* background-color: white;
    border-radius: 30px;
    margin:0;
    padding: 7px 40px;
    font-weight: 500; */
    background-color: white;
    border-radius: 30px !important;
    margin: 0;
    padding: 7px 0px;
    font-weight: 500;
    max-width: 105px;
    width: 105px;
    text-align: center;
}
.delivery_active{
    background-color: rgb(97,188,71) !important;
    color:white !important;
}
.delivery_location_page_content_heading {
    color: rgb(97,188,71) !important;
    margin-bottom: 15px !important;
    margin-top: 15px !important;
    padding-left: 12px;
}
.Select_delivery_page_inner_wrapper .Mui-selected{
    background-color: rgb(97,188,71) !important;
    color:white !important;
    border-radius: 30px
}
.Single_btn_select_delivery_location{
    margin:10px !important;
    border-radius: 30px !important;
}
.Select_delivery_locations_list_wrapper{
    display: flex;
    flex-wrap: wrap;
    margin-top:10px;
    font-size: 18px;
    column-gap: 10px;
    row-gap: 10px;
}

.Select_delivery_location_delivery_name:hover{
    background-color: rgb(97,188,71) !important;
    color:white !important;
}
/* =====================================End========================================================================================================================================================================================================================================================== */
/* ==============================AdminDashboard===================================================================================================================================================================================================================================== */
.Dashboard-page-tab-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(21, 64, 129);
    color: white;
    padding: 3px 19px;
    border-radius: 10px 10px 0px 0px;
}
 .Dashboard-page-tab-header-div-two{
        display: flex;
        justify-content: space-between;
        align-items: center;
 }
 .Dashboard-page-tab-header-div-two div {
    margin: 12px;
}
.Dashboard-page-tab-header-div-one h3 {
    margin: 0px;
    font-size: 25px;
    font-family: 'Poppins', sans-serif;
}
.Dashboard-page-tab-header-div-two h3 {
    font-size: 19px;
}
.Dashboard-page-tab-header-div-two p {
    font-size: 11px;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
}
 .Dashboard-page-tab-header-div-one h3, .Dashboard-page-tab-header-div-two p, .Dashboard-page-tab-header-div-two h3 {
    margin: 0px;
}
.AdminDashboard-whole-Main-wrapper {
      padding: 50px;
 }
 .AdminDashboard-tabs-left-side {
    width:35%;
    background: #fff;
    margin:10px;
}   

.AdminDashboard-tabs-left-side  .css-8je8zh-MuiTouchRipple-root {
    display: none;
}
.AdminDashboard-tabs-right-side {
    width: 60%;
    background: #fff;
    margin:10px;
}
.dashboard-order-list-wrapper-inner-div-one, .dashboard-order-list-wrapper-inner-div-two {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 3px;
    padding: 0px 16px;
}  
.Inner-tab {
    width: 100% !important;
    display: block !important;
    padding: 0px !important;
    text-align: start !important;
    max-width: 100% !important;
    align-items: flex-start !important;
}
.Dashboard-page-seperator-line {
    position: relative;
    width:70%;
}
.dashboard-order-list-wrapper {
    padding: 14px 0px;
    width: 100%;
    border-bottom: 3px solid rgb(242,242,242);
}
.Dashboard-page-seperator-line:before {
    content: '';
    background-color: rgb(210, 213, 205);
    color: rgb(210, 213, 205);
    position: absolute;
    width: 95%;
    height: 3px;
    left: 0px;
}
.Dashboard-page-seperator-line:after {
    content: '.';
    width: 9px;
    height: 9px;
    border-radius: 100%;
    background: rgb(210, 213, 205);
    position: absolute;
    right: 8px;
    top: -3px;
}
.dashboard-order-list-wrapper-inner-div-one p {
    text-transform: capitalize;
    text-align: center;
    background: rgb(224,232,208);
    padding: 0px 14px;
    font-size: 12px;
    padding: 2px 16px;
}
.dashboard-order-list-wrapper-inner-div-one h5 {
    color: rgb(21, 64, 129);
    font-size: 14px;
}

.dashboard-order-list-wrapper-inner-div-two p {
    width: 20%;
    text-transform: capitalize;
    font-weight: 600;
}
.dashboard-order-list-wrapper-inner-div-two-p-two{
    text-align: center;
}
.dashboard-order-list-wrapper-inner-div-two-p-one{
    text-align: start;
}
.AdminDashboard-tabs-left-side .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected .dashboard-order-list-wrapper{
    color: black !important;
    background: rgb(215 220 205);
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root{
    overflow:visible !important;
}
.AdminDashboard-tabs-left-side .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected .Dashboard-page-seperator-line:before {
    content: '';
    background-color: black;
    color: rgb(210, 213, 205);
    position: absolute;
    width: 95%;
    height: 3px;
    left: 0px;
}
.AdminDashboard-tabs-left-side .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected .Dashboard-page-seperator-line:after {
    content: '.';
    width: 9px;
    height: 9px;
    border-radius: 100%;
    background:black;
    position: absolute;
    right: 8px;
    top: -3px;
}

.dashboard-tab-inprogress {
    background-color: yellow !important;
    color: black;
}
.dashboard-tab-delivered{
    background-color: rgb(119,162,47) !important;
    color: #fff;
} 
.dashboard-tab-cancel{
    background-color:rgb(244,196,196) !important;
    color:#000;
}
.AdminDashboard-tabs-right-side .css-19kzrtu {
    padding: 0px;
}
.AdminDashboard-tabs-rightside-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(21, 64, 129);
    color: white;
    padding: 23px 21px;
    border-radius: 10px 10px 0px 0px;
}
.rightsideheader-p {
    font-size: 13px;
    padding: 0px 17px;
    border-radius: 4px;
    font-weight: 500;
}
h5.rightsideheader-h5 {
    font-weight: 800;
    font-size: 17px;
}
h5.rightsideheader-h5 span{
font-weight: 600;
font-size: 13px;
}

.AdminDashboard-tabs-rightside-content {
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
 }
.AdminDashboard-tabs-rightside-content-left-side{
    width:70%;
}
.AdminDashboard-tabs-rightside-content-right-side {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-self: baseline;
}
.AdminDashboard-tabs-rightside-content-left-side-upper-div {
    border: 3px solid rgb(210, 213, 205);;
    border-radius: 10px;
    padding: 20px;
    margin: 24px;
    position: relative;

}
.AdminDashboard-tabs-rightside-content-left-side-lower-div{

}
.AdminDashboard-tabs-rightside-content-inner-divs{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 8px;
    margin-left:0px;
}
.AdminDashboard-tabs-rightside-content-inner-divs:before{
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 3px;
    content: ' ';
    margin: auto;
    border-bottom: 3px dashed #e3e3e3;
}
.AdminDashboard-tabs-rightside-content-inner-divs span{  
        float: left;
		background: #FFF;
		position: relative;
        z-index: 999;
        font-weight:700;
        font-size:15px;
}
.AdminDashboard-tabs-rightside-content-inner-divs strong{
        float: right;
        background: #FFF;
        position: relative;
        z-index: 999;
        font-weight:700;
        font-size:15px;
}
.AdminDashboard-leftside-upper-div-p ,.AdminDashboard-leftside-upper-div-h3{
text-align: left;
}
p.AdminDashboard-leftside-upper-div-p {
    font-size: 13px;
    font-weight: 500;
    margin: 0px !important;
    /* padding-left: 10px; */
}
h3.AdminDashboard-leftside-upper-div-h3 {
    color: rgb(35,78,147);
    font-weight: 700;
    font-size: 17px;
    /* padding-left: 10px; */
}

.AdminDashboard-tabs-rightside-content-dashline{
    border-bottom: 2px dashed lightgrey;
    width: 75%;   
}
.AdminDashboard-tabs-rightside-content-inner-divs-last{
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin: 8px;
    margin-top:12px;
    margin-left:0px;
}
.AdminDashboard-tabs-rightside-content-inner-divs-last:before{
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 3px;
    content: ' ';
    margin: auto;
    border-bottom: 3px dashed #e3e3e3;
    }
    .AdminDashboard-tabs-rightside-content-inner-divs-last span{  
        float: left;
		background: #FFF;
		position: relative;
        z-index: 999;
        font-weight:700;
        color: rgb(35,78,147);
        font-size:15px;
}
    .AdminDashboard-tabs-rightside-content-inner-divs-last strong{
    float: right;
    background: #FFF;
    position: relative;
    z-index: 999;
    font-weight:700;
    color: rgb(35,78,147);
}   
.AdminDashboard-tabs-rightside-content-inner-divs-last h6{
    color:rgb(35,78,147);
    font-weight: 600;
    font-size: 14px;
}
.AdminDashboard-tabs-rightside-content-left-side-lower-div .AdminDashboard-leftside-upper-div-h3 {
    font-size: 15px;
    font-weight: 700;
}
.AdminDashboard-tabs-rightside-content-left-side-lower-div  .AdminDashboard-tabs-rightside-content-inner-divs h6{
width:44%;
}
.AdminDashboard-tabs-rightside-content-right-side-btn{
    background: linear-gradient(to right,rgb(150,150,150),rgb(109,109,109));
    color:white !important;
    font-weight: 600;
    text-transform: capitalize !important;
    margin: 15px !important;
    padding: 12px 21px !important;
    border-radius: 10px !important;
    width: 77%;
    margin: auto !important;
    margin-top: 22px !important;
}
.active-btndashboard{
    background: linear-gradient(to right, rgb(243,121,43),rgb(238,45,108)) !important; 
}
.Dashboard-accept-order-button-wrapper {
    margin: 28px;
    margin-top: 0px;

}
.Dashboard-accept-order-button{
    background-color: rgb(119,162,47) !important;
    color:#fff !important; 
    width:80%;
    padding: 10px 11px !important;
    font-weight: 600 !important;
    text-transform: capitalize !important;
    border-radius: 10px !important;
}
.DesktopMain-wrapper .MainHeader-logo-div img{
width:24%;
}
.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected.Inner-tab.css-1h9z7r5-MuiButtonBase-root-MuiTab-root{
position: relative;
}
/* .Mui-selected::after {
    content: '';
    width: 0;
    height: 0;
    right: -19px;
    top: 22px;
    border-top: 12px solid transparent;
    border-left: 20px solid rgb(215 220 205);
    border-bottom: 13px solid transparent;
    position: absolute;
} */

.Single-tab-wrapper-main .css-1cgx7kx-MuiTabs-scroller{
    overflow-y: unset !important; 
    overflow-x: unset !important;
}
.Single-tab-wrapper-main .css-1numsu8-MuiTabs-root {
    overflow: unset !important;
}
.Dashboard-accept-order-button-wrapper-cancel{
    margin: 28px;
    margin-top: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.Dashboard-accept-order-button-cancelrefund{
 background-color: rgb(227,23,76)  !important;
 color:#fff !important; 
 width:60% !important;
 padding: 10px 11px !important;
 font-weight: 600 !important;
 text-transform: capitalize !important;
 border-radius: 10px !important;
 margin:10px !important;
 margin-top:0px !important;
}
.Dashboard-accept-order-button-cancel{
    background-color: rgb(204,0,1)  !important;
    color:#fff !important; 
    width:60% !important;
    padding: 10px 11px !important;
    font-weight: 600 !important;
    text-transform: capitalize !important;
    border-radius: 10px !important;
    margin:10px !important;
    margin-top:0px !important;
   }
.Dashboard-accept-order-button-none{
    background: linear-gradient(to right,rgb(150,150,150),rgb(109,109,109));
    color:white !important;
    font-weight: 600;
    text-transform: capitalize !important;
    margin: 15px !important;
    padding: 12px 21px !important;
    border-radius: 10px !important;
    width: 77%;
    margin-top: 22px !important;
}   
 .dashboard-tab-cancelled{
    background-color:rgb(244,196,196) !important; 
 }  
 .Header-login-logout-btn{
    cursor: pointer;
 }
 .right-icon-class-wrapper{
    position: absolute;
    right: -12px;
    top: -13px;
    background-color: #77b43f;
    width:30px;
    height:30px;
    border-radius: 100%;
    border:2px solid white;
 }
 .right-icon-class-wrapper svg{
    color:#fff;
    padding: 3px;
 }
 .wrong-icon-class-wrapper{
    position: absolute;
    right: -12px;
    top: -13px;
    background-color: rgb(204,0,1);
    width:30px;
    height:30px;
    border-radius: 100%;
    border:2px solid white;
 }
 .wrong-icon-class-wrapper svg{
    color:#fff;
    padding: 3px;
 }


/* ==============================End===================================================================================================================================================================================================================================== */

/* ==============================AdminStores===================================================================================================================================================================================================================================== */
/* .AdminStore-page-table-wrapper table {
    width: 100%;
    margin: auto;
    border-collapse: collapse;
}
.AdminStore-page-table-wrapper table thead{
background-color:rgb(233,238,244);
color:rgb(35,78,147);
}
.Stores-page-content-whole-wrapper {
    background: White !important;
    width: 92%;
    margin: auto !important;
    margin-top:5rem !important;
    margin-bottom:5rem !important;
}


.AdminStore-page-table-wrapper table thead th {
    padding: 11px 11px;
    font-size: 15px;
    text-align: left;
  
}
.AdminStore-page-table-wrapper table tbody tr{
    border-bottom:1px solid rgb(233,238,244);
    padding: 8px 18px !important;
}
.AdminStore-page-table-wrapper table tbody td{
    text-align: left ;
}
.Dashboard-page-tab-header.Stores-page-header{
    padding: 23px;
}
.Dashboard-page-tab-header.Stores-page-header h3 {
    font-size: 25px;
}
.Store-page-table-image-class {
    width: 50%;
    padding: 10px;
    border: 2px solid rgb(233,238,244);
}
.StorePage-storeName-td{
    padding-left:0px;
    width: 40%;
}
.StorePage-storeName-td div h4 {
    font-weight: 900;
    font-size: 15px;
}
.StorePage-storeName-td div p {
    font-size: 13px;
}
.AdminStore-page-table-wrapper .css-qgjprd-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
    height: 40px;
    margin-top: 6px;
}  
.AdminStore-page-table-wrapper span.MuiButtonBase-root.MuiSwitch-switchBase.MuiSwitch-colorPrimary.PrivateSwitchBase-root.MuiSwitch-switchBase.MuiSwitch-colorPrimary.Mui-checked.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase{
    right:0px !important;
    bottom:0px !important; 
}
.AdminStore-page-table-wrapper .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase{
 left:0px !important;
}
.AdminStore-page-table-wrapper .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root{
    font-family: 'Poppins', sans-serif !important;
} */
/* ==============================End===================================================================================================================================================================================================================================== */

/* ===================HelpPage=============================================================================================================================================================================================================================================================== */
.Help-icon-wrapper{
    position:fixed;
    bottom:0rem;
    right:31rem;
    cursor: pointer;
}
.Help-icon-wrapper img{
    object-fit: cover;
    width:70px;
    height:70px;
}
.Help-page-Header-div{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:10px 24px;
}
.Help-page-Header-inner-div-one {
    display: flex;
}
.Help-page-Header-div-h4 {
    font-weight: 500;
    font-size: 20px;
}
.Help-Page-Main-content-wrapper {
    height: 100vh;
    background-color: white;
}
.Help_page-content-p{
    font-size: 13px;
    font-family: 'Poppins', sans-serif !important;
    text-align: start;
    margin:20px !important; 
}
h3.Help_page-content-h3 {
    text-align: start;
    margin: 20px !important;
    font-weight: 600;
    font-size: 16px;
}
h6.Help_page-content-h6 {
    text-align: start;
    margin: 20px !important;
    font-size: 12px;
    font-weight: 500;
}
.Help_page_content_heading {
    font-size: 16px;
    margin-left: 20px !important;
    font-weight: 500;
}
/* ===================End=============================================================================================================================================================================================================================================================== */
/* ===================MoreDetails=============================================================================================================================================================================================================================================================== */
.MoreDetials_page_Banner_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 37px;
}
.MoreDetials_page_Banner_wrapper img {
    width: 35%;
}
.More_Details_Page_h2{
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 0px !important;
    text-align: left;
    padding:20px;
    padding-bottom: 0px !important;
}
.More_Details_Page_h5{
    font-weight: 700;
    color: rgb(49 96 145);
    line-height: 19px;
    margin-bottom: 0px;
    font-size: 13.9px;
    text-align: start;
}
.More-details-page-content-p {
    line-height: 20px;
    font-size: 13px;
    font-weight: 400;
    color: black;
    text-align: start;
}
.ItemListMain-Wrapper h2 {
    font-size: 20px;
    text-align: left;
}
/* ===================End=============================================================================================================================================================================================================================================================== */

/* ==============================Responsive===================================================================================================================================================================================================================================== */
@media only screen and (max-width: 1024px) {
    .MainHeader-wrapper {
        padding: 15px 20px 5px;
    }
.AdminDashboard-tabs-rightside-content{
    display:block !important;
}
.AdminDashboard-tabs-rightside-content-left-side{
    width:100%;
}
.AdminDashboard-tabs-rightside-content-right-side{
    width:100%;
}
.AdminDashboard-whole-Main-wrapper {
    padding: 50px;
    padding-left: 10px;
    padding-right: 10px;
}
.AdminDashboard-tabs-left-side {
    width: 50%;
    background: #fff;
    margin: 10px;
}
.AdminDashboard-tabs-right-side {
    width: 50%;
    background: #fff;
    margin: 10px;
}

.AdminDashboard-tabs-rightside-content {
    padding: 0px;
  }
  .Dashboard-accept-order-button{
  width:100%;    
}
.Dashboard-accept-order-button-wrapper {
    margin: 28px;
    margin-top: 20px;
}
}

@media only screen and (max-width: 992px){
.StorePage-storeName-td div p{
    font-size: 11px;
}
.StorePage-storeName-td div h4{
    font-size: 13px;
}
.Store-page-table-image-class {
width:75%;
}
.AdminStore-page-table-wrapper .css-qgjprd-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
    height: 36px;
    width: 160px !important;
}
.AdminStore-page-table-wrapper .css-j204z7-MuiFormControlLabel-root {
margin-left:-2px;
}
}
@media only screen and (max-width: 767px){
.AdminDashboard-whole-Main-wrapper-div-one .MuiBox-root.css-orkwbp{
 display: block;
}
.AdminDashboard-tabs-left-side{
    width:100%;
    margin:-6px;
}
.AdminDashboard-tabs-right-side{
    width:100%;
    margin:-6px;
  }
}
@media screen and (max-width:600px) {
    .MainWhole-wrapper-container-2{
        width:100% !important;
    }
    body {
        overflow-x: hidden !important;
    }
    .MainWhole-wrapper-container{
        width:100% !important;
        height: 100vh;
    }
    .ItemList_Page_Next_button{
        width: 100% !important;
        left:0 !important;
        right:unset !important;
    }
   
    
 
    .CartPage-Main-wrapper .DetailsPage-content-wrapper-inner:nth-child(1){
    display: block !important;
    }
    .CartPage-Main-wrapper .ItemsListPage-ItemsList-Counter-wrapper{
        margin-left: 0rem;
    }
    .Congratulation-content-div-one-wrapper-inner-two h1 {
        font-size: 26px;
    }
        .Congratulation-content-div-one-wrapper-inner-two p {
            font-size: 15px;
    }  
    .Congratulation-content-div-one-wrapper-inner-one img {
            width: 130%;
   }    
           
.HomePage_slider_div-class {
    background-size: 182px !important;
}
h1.HomePage-Main-Heading-text {
    width: 90%;
    margin: auto !important;
    font-size: 27px;
    line-height: 28px;
}
.HomePage_slider_content_div_content h4 {
    margin-top: 9px !important;
    font-size: 20px;
    width: 66%;
    line-height: 24px;
}
.HomePage_slider_content_div_content {
    margin-bottom: 6rem;
    text-align: left;
    margin-left: 35px;
    width: 100%;
}
.owl-carousel .owl-stage-outer {
    margin-bottom: -45px;
}
.HomePage-Main-Wrapper-Container .owl-dots {
    margin-top: -4rem;
}
.HomePage_order_now_button {
    background-color: rgb(97,188,71);
    padding: 10px 45px;
    color: white;
    border: none !important;
    border-radius: 50pc;
    font-size: 28px;
    font-weight: 600;
    margin: 20px 10px !important;
    cursor: pointer;
    position: relative;
    z-index: 99999999 !important;
}
}
/* ==============================End===================================================================================================================================================================================================================================== */
.react-tel-input .country-list .country {
    position: relative;
    padding: 12px 9px 13px 46px;
    text-align: justify;
}
@media screen and (max-width:420px) {
    .HomePage_slider_content_div_content h4 {
        width: 43%;
    }
}